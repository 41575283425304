import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <div>
          <a className="App-nav" href="/about-us">
            About Us
          </a>
          <a className="App-nav" href="/contact-us">
            Contact Information
          </a>
        </div> */}
        <div>
          <img
            src="https://previews.123rf.com/images/aquir/aquir1701/aquir170100164/68660234-beta-testing-stamp-sign-seal.jpg"
            className="App-logo"
            alt="work in progress logo"
            id="under-construction-logo"
          />
        </div>
        <div>
          <h1 id="company-name-header">Scooter Testing</h1>
          <div>
            <p className="App-text" id="company-information">
              The purpose of this page is for me to hone my skills in ReactJS
              and TypeScript. Additionally I am a QA Engineer that is proficient
              in testing, and want to showcase some of my skills and frameworks.
            </p>
            <p className="App-text" id="company-information 2">
              I am working towards creating tutorials and training for QA
              Engineers. I started out learning on my own, and with no formal
              training. I hope that the upcoming content will be useful! If you
              want to chat, or want to talk QA just reach out!
            </p>
          </div>
          <a
            className="App-link"
            href="mailto:slcscooter@gmail.com?subject='Requesting more information'"
            target="_blank"
            rel="noopener noreferrer"
            id="contact-us-link"
          >
            Contact Me!
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
